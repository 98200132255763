









































































































import { ButtonGroup, TwSelect, VerticalFormBlock } from '@/app/components';
import { useQueryParams, useWindowEventListeners } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import { WindowEvent } from '@/app/constants';
import { computed, defineComponent, onUnmounted, PropType, Ref, ref, watch } from '@vue/composition-api';
import dayjs, { Dayjs } from 'dayjs';
import { equals, has, isNil } from 'ramda';
import { AccrualPeriodicityInterval, AccrualPeriodicityUnits } from '../constants';
import { Asset } from '../types';
import { AccrualPeriodicity } from '../types/accrual-periodicity.type';
import { Granularity } from '../types/granularity.type';

type GetRangeFunction = () => { startDate: Date; endDate: Date };
type DateRange = 'forever' | 'last30Days' | 'range';
type HistogramSearchQuery = {
    granularity: Granularity;
    startDate: Date;
    endDate: Date;
    extendedTimeline: boolean;
    accrualPeriodicity?: AccrualPeriodicity;
};

export default defineComponent({
    name: 'GraphFilters',
    model: {
        prop: 'metricsQuery',
        event: 'update-query',
    },
    props: {
        asset: { type: Object as PropType<Asset>, required: true },
        metricsQuery: {
            type: Object as PropType<HistogramSearchQuery>,
            default: () => {
                const now = dayjs.utc();
                return {
                    granularity: 'day',
                    startDate: now.subtract(30, 'days').toDate(),
                    endDate: now.toDate(),
                };
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        VerticalFormBlock,
        TwSelect,
        ButtonGroup,
    },
    setup(props, { root, emit }) {
        const router = useRouter();
        const { get, setMany } = useQueryParams(root, router, 'assets:view');
        const minStartDate: Date = dayjs.utc(props.asset.createdAt).toDate();
        const maxEndDate: Date = dayjs.utc().toDate();
        const orderedGranularities = ['minute', 'hour', 'day', 'week', 'month'];

        const mappedAccrualPeriodicityToGranularity: Record<
            string,
            {
                unit: Granularity;
                range: (defaultRange: DateRange | undefined) => DateRange; // eslint-disable-line no-unused-vars
                startDate: (
                    now: Dayjs, // eslint-disable-line no-unused-vars
                    defaultRange: DateRange | undefined, // eslint-disable-line no-unused-vars
                    defaultStartDate: Date | undefined, // eslint-disable-line no-unused-vars
                ) => Date;
                endDate: (
                    now: Dayjs, // eslint-disable-line no-unused-vars
                    defaultRange: DateRange | undefined, // eslint-disable-line no-unused-vars
                    defaultEndDate: Date | undefined, // eslint-disable-line no-unused-vars
                ) => Date;
            }
        > = {
            [AccrualPeriodicityInterval.Second]: {
                unit: 'minute',
                range: (defaultRange: DateRange | undefined) => (isNil(defaultRange) ? 'range' : defaultRange),
                startDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultStartDate: Date | undefined) => {
                    return isNil(defaultRange) || isNil(defaultStartDate)
                        ? now.subtract(1, 'hour').toDate()
                        : defaultStartDate;
                },
                endDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultEndDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultEndDate) ? now.toDate() : defaultEndDate,
            },
            [AccrualPeriodicityInterval.Minute]: {
                unit: 'minute',
                range: (defaultRange: DateRange | undefined) => (isNil(defaultRange) ? 'range' : defaultRange),
                startDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultStartDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultStartDate)
                        ? now.subtract(1, 'hour').toDate()
                        : defaultStartDate,
                endDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultEndDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultEndDate) ? now.toDate() : defaultEndDate,
            },
            [AccrualPeriodicityInterval.Hour]: {
                unit: 'hour',
                range: (defaultRange: DateRange | undefined) => (isNil(defaultRange) ? 'range' : defaultRange),
                startDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultStartDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultStartDate) ? now.subtract(3, 'day').toDate() : defaultStartDate,
                endDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultEndDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultEndDate) ? now.toDate() : defaultEndDate,
            },
            [AccrualPeriodicityInterval.Day]: {
                unit: 'day',
                range: (defaultRange: DateRange | undefined) => (isNil(defaultRange) ? 'last30Days' : defaultRange),
                startDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultStartDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultStartDate)
                        ? now.subtract(30, 'days').toDate()
                        : defaultStartDate,
                endDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultEndDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultEndDate) ? now.toDate() : defaultEndDate,
            },
            [AccrualPeriodicityInterval.Week]: {
                unit: 'week',
                range: (defaultRange: DateRange | undefined) => (isNil(defaultRange) ? 'range' : defaultRange),
                startDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultStartDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultStartDate)
                        ? now.subtract(1, 'year').toDate()
                        : defaultStartDate,
                endDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultEndDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultEndDate) ? now.toDate() : defaultEndDate,
            },
            [AccrualPeriodicityInterval.Month]: {
                unit: 'month',
                range: (defaultRange: DateRange | undefined) => (isNil(defaultRange) ? 'range' : defaultRange),
                startDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultStartDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultStartDate)
                        ? now.subtract(1, 'year').toDate()
                        : defaultStartDate,
                endDate: (now: Dayjs, defaultRange: DateRange | undefined, defaultEndDate: Date | undefined) =>
                    isNil(defaultRange) || isNil(defaultEndDate) ? now.toDate() : defaultEndDate,
            },
        };

        // asset's accrual periodicity if defined
        const accrualPeriodicity: AccrualPeriodicityInterval | undefined =
            props.asset?.metadata?.distribution?.accrualPeriodicity?.unit === AccrualPeriodicityInterval.NA
                ? undefined
                : props.asset?.metadata?.distribution?.accrualPeriodicity?.unit;

        /**
         * Calculate if granularity is available based on asset's accrual periodicity
         *
         * @param interval - accrual periodicity interval
         */
        const isGranularityOptionAvailable = (granularity: Granularity): boolean =>
            isNil(accrualPeriodicity) ||
            orderedGranularities.indexOf(mappedAccrualPeriodicityToGranularity[accrualPeriodicity].unit) <=
                orderedGranularities.indexOf(granularity);

        /**
         * Calculates default values for the range, startDate, endDate and granularity
         * Date range is first calculated based on query params
         *
         * Otherwise we provide a default depending on the accrual periodicity if available
         */
        const calculateDefaults = (): {
            defaultRange: DateRange;
            defaultStartDate: Date | undefined;
            defaultEndDate: Date | undefined;
            defaultGranularity: Granularity;
        } => {
            const now = dayjs.utc();
            let defaultRange: DateRange | undefined,
                defaultStartDate: Date | undefined,
                defaultEndDate: Date | undefined;

            if (get('range', false) === 'forever') {
                // Define accrual periodicity if set to forever in url
                defaultRange = 'forever';
                defaultStartDate = minStartDate;
                defaultEndDate = now.toDate();
            } else if (get('range', false) === 'last30Days') {
                // Define accrual periodicity if set to last30Days in url
                defaultRange = 'last30Days';
                defaultStartDate = now.subtract(30, 'days').toDate();
                defaultEndDate = now.toDate();
            } else if (
                (get('range', false) === 'range' && !isNil(get('start', false, undefined))) ||
                !isNil(get('end', false, undefined))
            ) {
                // Define accrual periodicity if set to range in url
                const start = get('start', false, undefined);
                const end = get('end', false, undefined);
                defaultRange = 'range';
                defaultStartDate = isNil(start) ? now.toDate() : dayjs.utc(start).toDate();
                defaultEndDate = isNil(end) ? now.toDate() : dayjs.utc(end).toDate();
            }

            const periodicity: AccrualPeriodicityInterval = accrualPeriodicity || AccrualPeriodicityInterval.Day;
            return {
                defaultGranularity: mappedAccrualPeriodicityToGranularity[periodicity].unit,
                defaultRange: mappedAccrualPeriodicityToGranularity[periodicity].range(defaultRange),
                defaultStartDate: mappedAccrualPeriodicityToGranularity[periodicity].startDate(
                    now,
                    defaultRange,
                    defaultStartDate,
                ),
                defaultEndDate: mappedAccrualPeriodicityToGranularity[periodicity].endDate(
                    now,
                    defaultRange,
                    defaultEndDate,
                ),
            };
        };

        /**
         * Calculate options and define if selectable and appropriate tooltip
         */
        const granularityOptions: { value: Granularity; label: string; selectable: boolean; tooltip: string }[] = [
            {
                value: 'minute',
                label: 'Minute',
                selectable: isGranularityOptionAvailable('minute'),
                tooltip: isGranularityOptionAvailable('minute')
                    ? `Set granularity to minute`
                    : `Granularity not available because accrual periodicity is set to a higher value`,
            },
            {
                value: 'hour',
                label: 'Hour',
                selectable: isGranularityOptionAvailable('hour'),
                tooltip: isGranularityOptionAvailable('hour')
                    ? `Set granularity to hour`
                    : `Granularity not available because accrual periodicity is set to a higher value`,
            },
            {
                value: 'day',
                label: 'Day',
                selectable: isGranularityOptionAvailable('day'),
                tooltip: isGranularityOptionAvailable('day')
                    ? `Set granularity to day`
                    : `Granularity not available because accrual periodicity is set to a higher value`,
            },
            {
                value: 'week',
                label: 'Week',
                selectable: isGranularityOptionAvailable('week'),
                tooltip: isGranularityOptionAvailable('week')
                    ? `Set granularity to week`
                    : `Granularity not available because accrual periodicity is set to a higher value`,
            },
            {
                value: 'month',
                label: 'Month',
                selectable: isGranularityOptionAvailable('month'),
                tooltip: isGranularityOptionAvailable('month')
                    ? `Set granularity to month`
                    : `Granularity not available because accrual periodicity is set to a higher value`,
            },
        ];

        const qualityMetricsFilters: { value: string; label: string; getRange?: GetRangeFunction }[] = [
            {
                value: 'last30Days',
                label: 'Last 30 Days',
                getRange: () => {
                    const today = dayjs().utc();
                    return {
                        startDate: today.subtract(30, 'day').toDate(),
                        endDate: today.toDate(),
                    };
                },
            },
            { value: 'range', label: 'Range' },
            {
                value: 'forever',
                label: 'Forever',
                getRange: () => {
                    const today = dayjs().utc();
                    return { startDate: minStartDate, endDate: today.toDate() };
                },
            },
        ];

        let { defaultRange, defaultStartDate, defaultEndDate, defaultGranularity } = calculateDefaults();
        const startDate: Ref<Date | undefined> = ref(defaultStartDate);
        const endDate: Ref<Date | undefined> = ref(defaultEndDate);

        const dateFilter: Ref<DateRange> = ref(defaultRange);

        // if granularity is not available then show default granularity instead
        const granularity: Ref<Granularity> = ref(
            isGranularityOptionAvailable(get('granularity', false, defaultGranularity))
                ? get('granularity', false, defaultGranularity)
                : defaultGranularity,
        );

        const range: Ref<{ startDate: Date | undefined; endDate: Date | undefined }> = computed(() => {
            const filterValue: { value: string; label: string; getRange?: GetRangeFunction } =
                qualityMetricsFilters.find(
                    (filter: { value: string; label: string }) => filter.value === dateFilter.value,
                ) || qualityMetricsFilters[0];

            if (has('getRange', filterValue) && !isNil(filterValue.getRange)) {
                return filterValue.getRange();
            }
            return { startDate: startDate.value, endDate: endDate.value };
        });

        const accrualPeriodicityExtendedSingular: Ref<AccrualPeriodicity | undefined> = computed(() =>
            accrualPeriodicity
                ? (AccrualPeriodicityUnits[accrualPeriodicity].extendedSingular as AccrualPeriodicity)
                : undefined,
        );

        const query: Ref<HistogramSearchQuery> = computed(() => {
            // we try to check here if the start date is smaller than the
            // creation of the asset to default to the creation
            // respecitvely if the end date is small
            let calculatedStartDate = range.value.startDate;
            let calculatedEndDate = range.value.endDate;
            if (
                !isNil(calculatedStartDate) &&
                !isNil(minStartDate) &&
                dayjs.utc(calculatedStartDate).isBefore(dayjs.utc(minStartDate))
            )
                calculatedStartDate = dayjs.utc(minStartDate).toDate();

            if (
                !isNil(calculatedEndDate) &&
                !isNil(calculatedStartDate) &&
                dayjs.utc(calculatedEndDate).isBefore(dayjs.utc(calculatedStartDate))
            )
                calculatedEndDate = dayjs.utc(calculatedStartDate).toDate();
            if (dayjs.utc(calculatedEndDate).isBefore(dayjs.utc(calculatedStartDate)))
                calculatedEndDate = calculatedStartDate;

            return {
                granularity: granularity.value,
                startDate: calculatedStartDate || dayjs.utc().toDate(),
                endDate: calculatedEndDate || dayjs.utc().toDate(),
                extendedTimeline: true,
                accrualPeriodicity: accrualPeriodicityExtendedSingular.value,
            };
        });

        watch(
            () => query.value,
            (
                newQuery: { granularity: Granularity; startDate: Date | undefined; endDate: Date | undefined },
                oldQuery:
                    | { granularity: Granularity; startDate: Date | undefined; endDate: Date | undefined }
                    | undefined,
            ) => {
                startDate.value = newQuery.startDate;
                endDate.value = newQuery.endDate;
                const startValue =
                    dateFilter.value === 'range' && !isNil(startDate.value) ? startDate.value.toISOString() : undefined;
                const endValue =
                    dateFilter.value === 'range' && !isNil(endDate.value) ? endDate.value.toISOString() : undefined;
                const newQueryParams = {
                    granularity: { value: granularity.value },
                    range: { value: dateFilter.value },
                    start: { value: startValue, default: undefined },
                    end: { value: endValue, default: undefined },
                };
                setMany(newQueryParams, isNil(oldQuery));
                if (!equals(newQuery, oldQuery)) emit('update-query', newQuery);
            },
            { immediate: true },
        );

        const unsubscribe: Ref<Function | undefined> = ref(undefined);

        const { onEvent } = useWindowEventListeners();

        const setDefaults = () => {
            const response = calculateDefaults();
            dateFilter.value = response.defaultRange;
            startDate.value = response.defaultStartDate;
            endDate.value = response.defaultEndDate;
            granularity.value = isGranularityOptionAvailable(get('granularity', false, response.defaultGranularity))
                ? get('granularity', false, response.defaultGranularity)
                : response.defaultGranularity;
        };

        // Add window event listener to handle back/forward browser buttons
        onEvent(WindowEvent.HistoryBackAndForward, setDefaults, (callback: any) => {
            unsubscribe.value = callback;
        });

        // remove query params for filters on unmount
        onUnmounted(async () => {
            setMany(
                {
                    granularity: { value: undefined },
                    range: { value: undefined },
                    start: { value: undefined },
                    end: { value: undefined },
                },
                true,
            );
            unsubscribe.value ? unsubscribe.value() : undefined;
        });

        return {
            query,
            startDate,
            endDate,
            granularityOptions,
            dateFilter,
            qualityMetricsFilters,
            granularity,
            minStartDate,
            maxEndDate,
        };
    },
});
